



























































































































import { defineComponent, ref, PropType, watch, computed } from '@vue/composition-api'
import MtsInput from './mts-input.vue'
import MtsButton from './mts-button.vue'
import PasswordRules from './password-rules.vue'
import PasswordPolitics from './password-politics.vue'
import { KeycloakAuthService } from '@servicedesk/auth-service'
import axios from 'axios'
import { validate } from 'vee-validate'

export default defineComponent({
  name: 'ChangeForm',
  components: {
    MtsInput,
    PasswordRules,
    PasswordPolitics,
    MtsButton
  },
  props: {
    authService: {
      type: Object as PropType<KeycloakAuthService>,
      required: true
    },
    isUpdateSuccessed: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object as PropType<Record<string, any> | undefined>,
      default: undefined
    }
  },
  emits: ['toggle-update-successed'],
  setup(props, ctx) {
    const oldPassword = ref('')
    const newPassword = ref('')
    const isNewPasswordError = ref(false)
    const repeatNewPassword = ref('')
    const validPasswordUpdate = ref(false)
    const disableUpdatePasswordButton = ref(false)
    const updateError = ref('')
    const ttl = ref(props.user?.exp * 1000)
    const isPasswordFocused = ref(false)
    const isPasswordBlured = ref(false)
    const passwordOnceFocused = ref(false)

    const isPasswordOnceFocusedValid = computed(
      () =>
        passwordOnceFocused.value &&
        (!newPassword.value || newPassword.value.length < 12 || newPassword.value.length > 30)
    )

    function createTimeout(ms: number): void {
      clearTimeout()

      setTimeout(async () => {
        await props.authService.updateToken()
        const user = await props.authService.getUserInfo()

        ttl.value = (user?.exp as number) * 1000 - Date.now()
      }, ms)
    }

    createTimeout(Number.isNaN(ttl.value) ? 60000 : ttl.value)
    watch(ttl, () => createTimeout(ttl.value))

    function updateValidPasswordUpdate(): void {
      validPasswordUpdate.value =
        !!oldPassword.value &&
        !!newPassword.value &&
        !!repeatNewPassword.value &&
        newPassword.value === repeatNewPassword.value
    }

    watch(newPassword, async (value) => {
      if (isNewPasswordError.value && value.length > 0) {
        isNewPasswordError.value = false
      }

      const { errors: mainErrors } = await validate(value, 'invalid|minmax:12,30')
      const { errors: partialErrors } = await validate(value, 'partial')

      disableUpdatePasswordButton.value = Boolean(mainErrors[0] || partialErrors[0])

      updateValidPasswordUpdate()
    })
    watch(oldPassword, () => {
      updateValidPasswordUpdate()
    })
    watch(repeatNewPassword, () => {
      updateValidPasswordUpdate()
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function handleError(text: string, status: number): Promise<void> {
      isNewPasswordError.value = true
      newPassword.value = ''
      repeatNewPassword.value = ''

      if (status === 401) {
        await props.authService.updateToken()

        handleUpdatePassword()
      }

      if (status === 500) {
        // 500 - Internal server error - password service url is missing or filled badly.
        // 500 - Internal server error - contact your administrator.
        // 500 - Password service answered with status code: + «»
        updateError.value = 'Что-то пошло не так, повторите попытку позже.'
      } else if (status === 403) {
        updateError.value = 'Нет прав для просмотра.'
      } else if (
        /Password contains whitespaces/.test(text) ||
        /Password validation failed: policy/.test(text) ||
        /Not enough optional policies were followed/.test(text)
      ) {
        updateError.value = 'Новый пароль не подходит под доменные политики.'
      } else {
        switch (text) {
          case 'AD sent error updating user password':
            updateError.value = 'Новый пароль не подходит под доменные политики.'
            break
          case 'New password should not match last.':
          case 'The new password must be different from the old one.':
            updateError.value = 'Новый пароль не должен совпадать с прошлыми.'
            break
          case 'The new password does not match the password policies.':
            updateError.value = 'Новый пароль не подходит под доменные политики.'
            break
          case 'Wrong old password.':
            updateError.value = 'Неверный старый пароль.'
            break
          case 'Passwords do not match.':
            updateError.value = 'Введеные пароли не совпадают.'
            break
          case 'Some of fields in request are filled badly.':
            updateError.value = 'Некоторые поля заполнены неверно.'
            break
          case 'Error updating password.':
            updateError.value = 'Что-то пошло не так, повторите попытку позже.'
            break
          default:
            updateError.value = 'Что-то пошло не так, повторите попытку позже.'
            break
        }
      }
    }

    async function handleUpdatePassword(): Promise<void> {
      const url = /test/.test(window.location.origin)
        ? `https://change_password:${oldPassword.value}@sso-test.dev.autobp.mts.ru/auth/realms/mts/password/user-update-password`
        : '/auth/realms/mts/password/user-update-password'

      axios({
        method: 'POST',
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${props.authService.accessToken}`
        },
        data: JSON.stringify({
          oldPassword: oldPassword.value,
          newPassword: newPassword.value,
          confirmation: repeatNewPassword.value
        })
      }).then(
        async (res) => {
          if (res.status === 200) {
            ctx.emit('toggle-update-successed', true)
          } else {
            handleError(res.statusText, res.status)
          }
        },
        (err) => {
          handleError(err.response.data, err.response.status)
        }
      )
    }

    async function logout(): Promise<void> {
      await props.authService.redirectToLogout()
    }

    function focusPasswordInput(): void {
      passwordOnceFocused.value = true
      isPasswordFocused.value = true
      isPasswordBlured.value = false
    }

    function blurPasswordInput(): void {
      isPasswordFocused.value = false
      isPasswordBlured.value = true
    }

    return {
      handleUpdatePassword,
      logout,
      oldPassword,
      isNewPasswordError,
      isPasswordOnceFocusedValid,
      newPassword,
      repeatNewPassword,
      validPasswordUpdate,
      updateError,
      disableUpdatePasswordButton,
      isPasswordFocused,
      isPasswordBlured,
      passwordOnceFocused,
      focusPasswordInput,
      blurPasswordInput
    }
  }
})
