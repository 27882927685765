

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'MtsButton',
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    disabled: {
      typr: Boolean,
      default: false
    },
    tabindex: {
      default: null
    }
  },
  emits: ['submit'],
  setup(_, ctx) {
    function submit(): void {
      ctx.emit('submit')
    }

    return {
      submit
    }
  }
})
