<template>
  <div class="mts-loading-abp" :class="loadingClass">
    <div class="mts-loading-abp__item" />
    <div class="mts-loading-abp__item" />
    <div class="mts-loading-abp__item" />
    <div class="mts-loading-abp__item" />
  </div>
</template>

<script>
export default {
  name: 'MtsLoadingAbp',
  props: {
    /**
     * Размер
     * @values m, s
     */
    size: {
      type: String,
      default: 'm',
      validator(val) {
        return ['m', 's'].includes(val)
      }
    }
  },
  computed: {
    loadingClass() {
      const block = 'mts-loading-abp'

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return this.loadingSize ? `${block}_${this.loadingSize}` : ''
    },
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize
    },
    loadingSize() {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size
    }
  }
}
</script>

<style lang="scss">
.mts-loading-abp {
  display: flex;
  justify-content: center;

  &__item {
    background-color: #e2e5eb;

    &:nth-child(odd) {
      animation: animOdd 1.8s infinite;
    }

    &:nth-child(even) {
      animation: animEven 1.8s infinite;
    }
  }

  &_m {
    padding: 22px 0;

    .mts-loading-abp {
      &__item {
        width: 22px;
        height: 60px;

        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }

  &_s {
    padding: 16px 0;

    .mts-loading-abp {
      &__item {
        width: 12px;
        height: 34px;

        &:not(:first-child) {
          margin-left: 4px;
        }
      }
    }
  }
}

@keyframes animEven {
  0% {
    transform: translate3d(10%, -10%, 0) skew(-19deg);
  }

  50% {
    transform: translate3d(-10%, 10%, 0) skew(-19deg);
  }

  100% {
    transform: translate3d(10%, -10%, 0) skew(-19deg);
  }
}

@keyframes animOdd {
  0% {
    transform: translate3d(-10%, 10%, 0) skew(-19deg);
  }

  50% {
    transform: translate3d(10%, -10%, 0) skew(-19deg);
  }

  100% {
    transform: translate3d(-10%, 10%, 0) skew(-19deg);
  }
}
</style>
