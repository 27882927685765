import Vue, { VNode } from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueCompositionAPI from '@vue/composition-api'
import VueMask, { VueMaskFilter, VueMaskDirective } from 'v-mask'

import './styles/index.scss'
import App from './App.vue'
import {
  digits,
  lowerCases,
  minmax,
  password,
  required,
  special,
  upperCases,
  invalid,
  partial
} from './helpers/validators'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.use(VueMask)
Vue.filter('VMask', VueMaskFilter)
Vue.directive('mask', VueMaskDirective)
Vue.use(VueCompositionAPI)

minmax()
lowerCases()
upperCases()
digits()
special()
password()
required()
invalid()
partial()

const app = new Vue({
  name: 'PasswordChange',
  render: (h): VNode => h(App)
})

app.$mount('.change-password-page')
