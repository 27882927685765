

















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'PasswordPolitics',
  props: {
    showList: {
      type: Boolean,
      required: true
    },
    titleText: {
      type: String,
      required: true
    },
    oldPasswordText: {
      type: String,
      required: true
    },
    easyPasswordText: {
      type: String,
      required: true
    },
    repeatPasswordText: {
      type: String,
      required: true
    }
  }
})
