



















import { defineComponent } from '@vue/composition-api'
import MtsButton from './mts-button.vue'

export default defineComponent({
  name: 'SuccessForm',
  components: {
    MtsButton
  },
  emits: ['submit'],
  setup(_, ctx) {
    function submitForm(): void {
      ctx.emit('submit', false)
    }

    return {
      submitForm
    }
  }
})
