











































































import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  name: 'MtsInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    error: {
      type: [Boolean, String],
      default: false
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    initRegister: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    vmask: {
      type: String,
      default: ''
    },
    tabindex: {
      default: null
    }
  },
  emits: ['input', 'register', 'focus', 'blur'],
  setup(props, ctx) {
    const hasError = ref(false)
    const isVisiblePassword = ref(false)
    const inputType = ref(props.type)
    const inputRef = ref<HTMLInputElement | null>(null)

    watch(
      () => props.error,
      (value: boolean | string) => (hasError.value = !!value)
    )
    watch(
      () => props.disabled,
      () => ctx.emit('input', '')
    )
    watch(
      () => props.initRegister,
      (value: boolean) => {
        if (value) ctx.emit('register', props.name)
      }
    )

    function onInput(value: any): void {
      hasError.value = props.validation ? !!props.error : false
      ctx.emit('input', value.target.value)
    }

    function onFocus(): void {
      ctx.emit('focus')
    }

    function onBlur(): void {
      ctx.emit('blur')
    }

    function onClear(): void {
      ctx.emit('input', '')
      setTimeout(() => inputRef.value?.focus(), 0)
    }

    function toggleVisibilityPassword(): void {
      isVisiblePassword.value = !isVisiblePassword.value
      inputType.value = isVisiblePassword.value ? 'text' : 'password'
      setTimeout(() => inputRef.value?.focus(), 0)
    }

    return {
      hasError,
      isVisiblePassword,
      inputType,
      inputRef,
      onClear,
      onInput,
      onFocus,
      onBlur,
      toggleVisibilityPassword
    }
  }
})
