























































import { computed, defineComponent, ref } from '@vue/composition-api'
import { KeycloakAuthService } from '@servicedesk/auth-service'
import SuccessForm from './components/success-form.vue'
import ChangeForm from './components/change-form.vue'
import MtsLoadingAbp from './components/loading-abp.vue'

export default defineComponent({
  name: 'App',
  components: {
    SuccessForm,
    ChangeForm,
    MtsLoadingAbp
  },
  setup() {
    const isUpdateSuccessed = ref(false)
    const user = ref<Record<string, unknown> | undefined>()
    const authService = computed(
      () =>
        new KeycloakAuthService({
          clientId: 'password-change',
          realm: 'mts',
          url: '/auth',
          cacheEnabled: true,
          debugEnabled: false
        })
    )

    async function init(): Promise<void> {
      if (!/localhost/.test(location.origin)) {
        await authService.value.updateToken()
        user.value = await authService.value.getUserInfo()
      }
    }

    init()

    function toggleUpdateSuccessed(value: boolean): void {
      isUpdateSuccessed.value = value
    }

    return {
      isUpdateSuccessed,
      authService,
      toggleUpdateSuccessed,
      user
    }
  }
})
