import { extend, validate } from 'vee-validate'
import type { ValidationRuleResult } from 'vee-validate/dist/types/types'

export function partial(): void {
  extend('partial', {
    validate: async function validateValue(value: string): Promise<boolean | ValidationRuleResult> {
      const { errors: lowerCaseErrors } = await validate(value, 'lowerCases')
      const { errors: upperCaseErrors } = await validate(value, 'upperCases')
      const { errors: digitsErrors } = await validate(value, 'digits')
      const { errors: specialErrors } = await validate(value, 'special')

      const errors = [...lowerCaseErrors, ...upperCaseErrors, ...digitsErrors, ...specialErrors]

      if (errors.length <= 1) {
        return true
      }

      let errorsResult = ''

      if (lowerCaseErrors.length) {
        errorsResult += 'lowerCase '
      }

      if (upperCaseErrors.length) {
        errorsResult += 'upperCase '
      }

      if (digitsErrors.length) {
        errorsResult += 'digits '
      }

      if (specialErrors.length) {
        errorsResult += 'special'
      }

      return { valid: false, data: { error: errorsResult } }
    },
    params: ['message'],
    message: '{message}'
  })
}

export function minmax(): void {
  extend('minmax', {
    validate: function validateValue(value: string, _ref: Record<string, any>) {
      return value.length >= _ref.min && value.length <= _ref.max
    },
    params: ['min', 'max']
  })
}

export function lowerCases(): void {
  extend('lowerCases', {
    validate: function validateValue(value) {
      // Проверяет строку на наличие прописных букв: (?=.*[a-z])
      return /(?=.*[a-z])/g.test(value)
    }
  })
}

export function upperCases(): void {
  extend('upperCases', {
    validate: function validateValue(value) {
      // Проверяет строку на наличие заглавных букв: (?=.*[A-Z])
      return /(?=.*[A-Z])/g.test(value)
    }
  })
}

export function digits(): void {
  extend('digits', {
    validate: function validateValue(value) {
      // Проверяет строку на наличие цифр: (?=.*\d)
      return /(?=.*\d)/g.test(value)
    }
  })
}

export function special(): void {
  extend('special', {
    validate: function validateValue(value) {
      /* Проверяет строку на наличие спец. символов: «!», «@», «#», «!», «@», «#», «$», «%», «^», «&», «*»,
      «(», «)», «,», «.», «?», «"», «:», «{», «}», «|», «<», «>», «/», «\», «+», «=», «-», «_», «—», «~», «`», 
      «[», «]», «;», «№», «'», «±»: (?=.*[!@#$%^&*(),.?":{}|<>/\\+=\-_—~`\[\];№'\±]) */
      // eslint-disable-next-line no-useless-escape
      return /(?=.*[!@#$%^&*(),.?":{}|<>/\\+=\-_—~`\[\];№'\±])/g.test(value)
    }
  })
}

export function password(): void {
  extend('password', {
    validate: function validateValue(value, _ref2: Record<string, any>) {
      return value === _ref2.target
    },
    params: ['target', 'message'],
    message: '{message}'
  })
}

export function required(): void {
  extend('required', {
    validate: function validateValue(value) {
      return {
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1
      }
    },
    computesRequired: true
  })
}

export function invalid(): void {
  extend('invalid', {
    validate: function validateValue(value) {
      /* Проверяет строку на наличие только заглавных и прописных латинских букв, цифр и спец. символов: «!», «@», 
      «#», «!», «@», «#», «$», «%», «^», «&», «*», «(», «)», «,», «.», «?», «"», «:», «{», «}», «|», «<», «>», «/», 
      «\», «+», «=», «-», «_», «—», «~», «`», «[», «]», «;», «№», «'», «±»: (?=.*[!@#$%^&*(),.?":{}|<>/\\+=\-_—~`\[\];№'\±]) */
      // eslint-disable-next-line no-useless-escape
      return /^[A-Za-z0-9!@#$%^&*(),.?":{}|<>/\\+=\-_—~`\[\];№'\±]+$/.test(value)
    },
    params: ['message'],
    message: '{message}'
  })
}
