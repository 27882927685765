























import { computed, defineComponent, ref, watch } from '@vue/composition-api'

interface CheckListItem {
  message: string
  valid: string
  error: boolean
}

const CHECK_MIN_MAX_ERROR = [{ message: '', valid: 'minmax', error: false }]

const CHECK_LIST_ERRORS: CheckListItem[] = [
  { message: '', valid: 'upperCases', error: false },
  { message: '', valid: 'lowerCases', error: false },
  { message: '', valid: 'digits', error: false },
  { message: '', valid: 'special', error: false }
]

export default defineComponent({
  name: 'PasswordRules',
  components: {},
  props: {
    failed: {
      default: {
        invalid: '',
        password: '',
        partial: '',
        minmax: ''
      }
    },
    forceShow: {
      type: Boolean,
      required: true
    },
    forceHide: {
      type: Boolean,
      required: true
    },
    messageMinMax: {
      type: String,
      required: true
    },
    messageUpperCases: {
      type: String,
      required: true
    },
    messageLowerCases: {
      type: String,
      required: true
    },
    messageDigits: {
      type: String,
      required: true
    },
    messageSpecial: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const errorMessage = ref('')
    const showMinMax = ref(false)
    const showList = ref(false)

    const checkMinMaxError = computed(() => {
      const errorKeys = Object.keys(props.failed)

      return CHECK_MIN_MAX_ERROR.map((item: CheckListItem) => {
        item.message = props.messageMinMax
        if (item.valid === 'minmax') item.message = props.messageMinMax
        item.error = errorKeys.includes(item.valid)

        return item
      })
    })
    const checkListErrors = computed(() => {
      const errorKeys = Object.keys(props.failed)

      return CHECK_LIST_ERRORS.map((item: CheckListItem) => {
        item.message = props.messageMinMax
        if (item.valid === 'upperCases') item.message = props.messageUpperCases
        if (item.valid === 'lowerCases') item.message = props.messageLowerCases
        if (item.valid === 'digits') item.message = props.messageDigits
        if (item.valid === 'special') item.message = props.messageSpecial
        item.error = errorKeys.includes(item.valid)

        return item
      })
    })

    watch(
      () => props.failed,
      (value) => {
        if (value.partial) {
          const errorKeys = Object.keys(value)

          showList.value = errorKeys.some((key) =>
            ['lowerCases', 'upperCases', 'digits', 'special'].includes(key)
          )

          errorMessage.value = ''

          if (errorKeys.length === 1 && errorKeys.includes('password')) {
            errorMessage.value = props.failed.password
          }

          if (errorKeys.includes('required')) {
            errorMessage.value = ''
            showList.value = false
          }
        }

        if (value.minmax) {
          const errorKeys = Object.keys(value)
          showMinMax.value = errorKeys.includes('minmax')
        }

        if (!value.partial && !value.minmax) {
          showMinMax.value = false
          showList.value = false
          errorMessage.value = ''
        }
      }
    )

    return {
      errorMessage,
      showMinMax,
      showList,
      checkListErrors,
      checkMinMaxError
    }
  }
})
